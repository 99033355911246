import { useTranslation } from "react-i18next";

const TextsWrapper = () => {
  const { t } = useTranslation("login");

  return (
    <div className="flex-1 flex-col justify-center gap-10 p-6 tracking-wider text-white hidden lg:flex -mt-32">
      <span className="text-2xl xl:text-3xl font-semibold">{t("heading")}</span>

      <span className="md:text-4xl xl:text-6xl font-semibold !leading-snug [text-wrap:pretty] max-w-4xl">
        {t("subHeading")}
      </span>

      <span className="text-lg xl:text-xl font-medium !leading-snug [text-wrap:pretty] max-w-4xl">
        {t("cta")}
      </span>
    </div>
  );
};

export default TextsWrapper;
