const gradients = [
  "blue-gradient-bg",
  "pink-gradient-bg",
  "orange-gradient-bg",
];

export const getGradientBg = () => {
  return gradients[Math.floor(Math.random() * gradients.length)];
};


