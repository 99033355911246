import Logo from "@/components/Logo";
import Paragraph from "@/components/ui/Paragraph";
import TextsWrapper from "./components/TextsWrapper";
import Head from "next/head";
import LoginForm from "./components/LoginForm";
import Link from "next/link";
import Image from "next/image";
import { getGradientBg } from "@/components/util/getGradientBg";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const Page = () => {
  const { t } = useTranslation("login");
  const gradientBg = useMemo(() => getGradientBg(), []);

  return (
    <>
      <Head>
        <title>{t("pageHeading")} </title>
      </Head>

      <main
        className={
          "flex w-full min-h-svh relative login-bg text-foreground max-lg:p-4 " +
          gradientBg
        }
      >
        <Image
          className="absolute inset-0 object-cover animate-opacityIncrease"
          layout="fill"
          src="/logo-pattern.png"
          alt="logo pattern background"
        />

        <div className="flex flex-col items-center justify-center bg-background z-10 rounded-xl lg:rounded-e-[48px] lg:rounded-s-none max-w-full max-lg:h-fit md:mt-8 lg:mt-0 transition-transform ltr:lg:animate-slideInFromLeft rtl:lg:animate-slideInFromRight max-lg:py-12 p-6 max-lg:mx-auto lg:flex-1">
          <Link href="/" passHref>
            <a className="mx-auto block">
              <Logo />
            </a>
          </Link>

          <Paragraph
            className="mt-8 lg:mt-16 lowercase first-letter:capitalize"
            variant="title"
          >
            {t("welcomeBack")}
          </Paragraph>
          <Paragraph className="text-muted-foreground" variant="subtitle">
            {t("enterInfo")}
          </Paragraph>
          <LoginForm />
        </div>

        <TextsWrapper />
      </main>
    </>
  );
};

Page.theme = "light";

export default Page;
